import { createAction, createSlice } from '@reduxjs/toolkit';
import { LoadingStatus } from '../../api/app.types';
import { createAsyncThunkWithError } from '../../redux/utils';
import {
  JobFunction,
  JobFunctionInfo,
  JobFunctionTag,
  JobFunctionTagInfo,
} from './types';
import { RootState } from '../../store';
import {
  createJobFunction,
  createJobFunctionTag,
  deleteJobFunction,
  deleteJobFunctionTag,
  getJobFunctions,
  getJobFunctionTags,
  getMCellEmployeeSelection,
  getMCellEmployeesInManufacturingLocation,
  getMCellEmployeesLoggedIn,
  getMCellLoginLastUpdatedDate,
  downloadEmployeeReportCsv,
  updateJobFunction,
  updateJobFunctionTag,
  triggerManualEmployeeRefresh,
  checkEmployeeRefreshStatus,
} from './api';
import { MCellEmployee, MCellEmployeeInMCell } from '../resourceTracking/types';
import { saveBlobToFile } from '../../utils/file';

export type MCellEmployeeState = {
  jobFunctions: JobFunction[];
  jobFunctionsStatus: LoadingStatus;
  jobFunctionTags: JobFunctionTag[];
  jobFunctionTagsStatus: LoadingStatus;
  mCellEmployeesLoggedIn: MCellEmployee[];
  mCellEmployeesLoggedInStatus: LoadingStatus;
  mCellEmployeesInMCell: MCellEmployeeInMCell[];
  mCellEmployeesInMCellStatus: LoadingStatus;
  loginLastUpdatedDate?: string;
  loginLastUpdatedDateStatus: LoadingStatus;
  mCellEmployeeSelection: MCellEmployee[];
  mCellEmployeeSelectionStatus: LoadingStatus;
  reportsStatus: LoadingStatus;
  employeeRefreshRunning: boolean;
  employeeRefreshRunningStatus: LoadingStatus;
};

export const initialState: MCellEmployeeState = {
  jobFunctions: [],
  jobFunctionsStatus: 'idle',
  jobFunctionTags: [],
  jobFunctionTagsStatus: 'idle',
  mCellEmployeesLoggedIn: [],
  mCellEmployeesLoggedInStatus: 'idle',
  mCellEmployeesInMCell: [],
  mCellEmployeesInMCellStatus: 'idle',
  loginLastUpdatedDate: undefined,
  loginLastUpdatedDateStatus: 'idle',
  mCellEmployeeSelection: [],
  mCellEmployeeSelectionStatus: 'idle',
  reportsStatus: 'idle',
  employeeRefreshRunning: false,
  employeeRefreshRunningStatus: 'idle',
};

/**
 * Action to delete a Job Function in the store
 */
export const deleteJobFunctionAction = createAction<string>(
  'mCellEmployee/deleteJobFunctionAction',
);

/**
 * Action to add or update a Job Function in the store
 */
export const addOrUpdateJobFunctionAction = createAction<JobFunctionInfo>(
  'mCellEmployee/addOrUpdateJobFunctionAction',
);

/**
 * Action to delete a Job Function Tag in the store
 */
export const deleteJobFunctionTagAction = createAction<string>(
  'mCellEmployee/deleteJobFunctionTagAction',
);

/**
 * Action to add or update a Job Function Tag in the store
 */
export const addOrUpdateJobFunctionTagAction = createAction<JobFunctionTagInfo>(
  'mCellEmployee/addOrUpdateJobFunctionTagAction',
);

/**
 * Action to update the Employee Refresh Running status
 */
export const updateEmployeeRefreshAction = createAction<boolean>(
  'mCellEmployee/updateEmployeeRefreshAction',
);

/**
 * Thunk for fetching Job Functions from the backend and setting
 * in the store
 */
export const getJobFunctionsThunk = createAsyncThunkWithError(
  'mCellEmployee/getJobFunctionsThunk',
  getJobFunctions,
  'errorFetchingJobFunctions',
);

/**
 * Thunk for creating a Job Function in the backend while optimistically setting
 * in the store
 */
export const createJobFunctionThunk = createAsyncThunkWithError(
  'mCellEmployee/createJobFunctionsThunk',
  async (info: JobFunctionInfo, { dispatch }): Promise<JobFunction> => {
    dispatch(addOrUpdateJobFunctionAction(info));
    return createJobFunction(info);
  },
  'errorCreatingJobFunction',
);

/**
 * Thunk for updating a Job Function in the backend while optimistically setting
 * in the store
 */
export const updateJobFunctionThunk = createAsyncThunkWithError(
  'resourceTracking/updateJobFunctionThunk',
  async (info: JobFunctionInfo, { dispatch }): Promise<JobFunction> => {
    dispatch(addOrUpdateJobFunctionAction(info));
    return updateJobFunction(info);
  },
  'errorUpdatingJobFunction',
);

/**
 * Thunk for deleting a Job Function in the backend while optimistically
 * updating the store
 */
export const deleteJobFunctionThunk = createAsyncThunkWithError(
  'mCellEmployee/deleteJobFunctionThunk',
  async (id: string, { dispatch }): Promise<string> => {
    dispatch(deleteJobFunctionAction(id));
    return deleteJobFunction(id);
  },
  'errorDeletingJobFunction',
);

/**
 * Thunk for fetching Job Function Tags from the backend and setting in the
 * store
 */
export const getJobFunctionTagsThunk = createAsyncThunkWithError(
  'mCellEmployee/getJobFunctionTagsThunk',
  getJobFunctionTags,
  'errorFetchingJobFunctionTags',
);

/**
 * Thunk for creating a Job Function Tag in the backend while optimistically
 * setting in the store
 */
export const createJobFunctionTagThunk = createAsyncThunkWithError(
  'mCellEmployee/createJobFunctionTagThunk',
  async (info: JobFunctionTagInfo, { dispatch }): Promise<JobFunctionTag> => {
    dispatch(addOrUpdateJobFunctionTagAction(info));
    return createJobFunctionTag(info);
  },
  'errorCreatingJobFunctionTag',
);

/**
 * Thunk for updating a Job Function Tag in the backend while optimistically
 * setting in the store
 */
export const updateJobFunctionTagThunk = createAsyncThunkWithError(
  'resourceTracking/updateJobFunctionTagThunk',
  async (info: JobFunctionTagInfo, { dispatch }): Promise<JobFunctionTag> => {
    dispatch(addOrUpdateJobFunctionTagAction(info));
    return updateJobFunctionTag(info);
  },
  'errorUpdatingJobFunctionTag',
);

/**
 * Thunk for deleting a Job Function Tag in the backend while optimistically
 * updating the store
 */
export const deleteJobFunctionTagThunk = createAsyncThunkWithError(
  'mCellEmployee/deleteJobFunctionTagThunk',
  async (id: string, { dispatch }): Promise<string> => {
    dispatch(deleteJobFunctionTagAction(id));
    return deleteJobFunctionTag(id);
  },
  'errorDeletingJobFunctionTag',
);

/**
 * Thunk for fetching logged in MCell Employees
 */
export const getMCellEmployeesLoggedInThunk = createAsyncThunkWithError(
  'mCellEmployee/getMCellEmployeesLoggedInThunk',
  getMCellEmployeesLoggedIn,
  'errorFetchingLoggedInEmployees',
);

/**
 * Thunk for fetching logged in MCell Employees
 */
export const getMCellEmployeesInManufacturingLocationThunk =
  createAsyncThunkWithError(
    'mCellEmployee/getMCellEmployeesInManufacturingLocationThunk',
    getMCellEmployeesInManufacturingLocation,
    'errorFetchingEmployeesInManufacturingLocation',
  );

/**
 * Thunk for fetching lMCell Login Last Updated date
 */
export const getMCellLoginLastUpdatedDateThunk = createAsyncThunkWithError(
  'mCellEmployee/getMCellLoginLastUpdatedDateThunk',
  getMCellLoginLastUpdatedDate,
  'errorFetchingLoginLastUpdatedDate',
);

/**
 * Thunk for fetching MCell Employees optionally filtered by inventory center for use in select component
 */
export const getMCellEmployeeSelectionThunk = createAsyncThunkWithError(
  'mCellEmployee/getMCellEmployeeSelectionThunk',
  getMCellEmployeeSelection,
  'errorFetchingEmployees',
);

/**
 * Thunk for fetching Employee Search Report Data as .csv file from the backend and saving to the browser
 */
export const downloadEmployeeReportCsvThunk = createAsyncThunkWithError(
  'report/downloadEmployeeReportCsvThunk',
  async (params: { filename: string }) => {
    const data = await downloadEmployeeReportCsv();
    saveBlobToFile(data, params.filename);
  },
  'errorExportingEmployeeSearchReport',
);

/**
 * Thunk for manually triggering an employee refresh
 */
export const triggerManualEmployeeRefreshThunk = createAsyncThunkWithError(
  'mCellEmployee/triggerManualEmployeeRefreshThunk',
  triggerManualEmployeeRefresh,
  'errorTriggeringManualEmployeeRefresh',
);

/**
 * Thunk for checking the status of the employee refresh
 */
export const checkEmployeeRefreshStatusThunk = createAsyncThunkWithError(
  'mCellEmployee/checkEmployeeRefreshStatusThunk',
  checkEmployeeRefreshStatus,
  'errorCheckingEmployeeRefreshStatus',
);

export const mCellEmployeeSlice = createSlice({
  name: 'mCellEmployee',
  initialState,
  reducers: {
    addOrUpdateJobFunctionAction: (state, action) => {
      const index = state.jobFunctions.findIndex(
        (s) => s.id === action.payload.id,
      );
      if (index !== -1) {
        state.jobFunctions[index] = action.payload;
      } else {
        state.jobFunctions.push(action.payload);
      }
    },
    deleteJobFunctionAction: (state, action) => {
      const index = state.jobFunctions.findIndex(
        (s) => s.id === action.payload,
      );
      if (index > -1) {
        state.jobFunctions.splice(index, 1);
      }
    },
    addOrUpdateJobFunctionTagAction: (state, action) => {
      const index = state.jobFunctionTags.findIndex(
        (s) => s.id === action.payload.id,
      );
      if (index !== -1) {
        state.jobFunctionTags[index] = action.payload;
      } else {
        state.jobFunctionTags.push(action.payload);
      }
    },
    deleteJobFunctionTagAction: (state, action) => {
      const index = state.jobFunctionTags.findIndex(
        (s) => s.id === action.payload,
      );
      if (index > -1) {
        state.jobFunctionTags.splice(index, 1);
      }
    },
    updateEmployeeRefreshAction: (state, action) => {
      state.employeeRefreshRunning = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createJobFunctionThunk.pending, (state) => {
        state.jobFunctionsStatus = 'loading';
      })
      .addCase(createJobFunctionThunk.fulfilled, (state) => {
        state.jobFunctionsStatus = 'succeeded';
      })
      .addCase(createJobFunctionThunk.rejected, (state) => {
        state.jobFunctionsStatus = 'failed';
      })
      .addCase(getJobFunctionsThunk.pending, (state) => {
        state.jobFunctionsStatus = 'loading';
      })
      .addCase(getJobFunctionsThunk.fulfilled, (state, { payload }) => {
        state.jobFunctionsStatus = 'succeeded';
        state.jobFunctions = payload;
      })
      .addCase(getJobFunctionsThunk.rejected, (state) => {
        state.jobFunctionsStatus = 'failed';
      })
      .addCase(updateJobFunctionThunk.pending, (state) => {
        state.jobFunctionsStatus = 'loading';
      })
      .addCase(updateJobFunctionThunk.fulfilled, (state, { payload }) => {
        const index = state.jobFunctions.findIndex((s) => s.id === payload.id);
        if (index !== -1) {
          state.jobFunctions[index] = payload;
        } else {
          state.jobFunctions.push(payload);
        }
        state.jobFunctionsStatus = 'succeeded';
      })
      .addCase(updateJobFunctionThunk.rejected, (state) => {
        state.jobFunctionsStatus = 'failed';
      })
      .addCase(deleteJobFunctionThunk.pending, (state) => {
        state.jobFunctionsStatus = 'loading';
      })
      .addCase(deleteJobFunctionThunk.fulfilled, (state, { payload }) => {
        state.jobFunctionsStatus = 'succeeded';
        const index = state.jobFunctions.findIndex((s) => s.id === payload);
        if (index > -1) {
          state.jobFunctions.splice(index, 1);
        }
      })
      .addCase(deleteJobFunctionThunk.rejected, (state) => {
        state.jobFunctionsStatus = 'failed';
      })
      .addCase(createJobFunctionTagThunk.pending, (state) => {
        state.jobFunctionTagsStatus = 'loading';
      })
      .addCase(createJobFunctionTagThunk.fulfilled, (state) => {
        state.jobFunctionTagsStatus = 'succeeded';
      })
      .addCase(createJobFunctionTagThunk.rejected, (state) => {
        state.jobFunctionTagsStatus = 'failed';
      })
      .addCase(getJobFunctionTagsThunk.pending, (state) => {
        state.jobFunctionTagsStatus = 'loading';
      })
      .addCase(getJobFunctionTagsThunk.fulfilled, (state, { payload }) => {
        state.jobFunctionTagsStatus = 'succeeded';
        state.jobFunctionTags = payload;
      })
      .addCase(getJobFunctionTagsThunk.rejected, (state) => {
        state.jobFunctionTagsStatus = 'failed';
      })
      .addCase(updateJobFunctionTagThunk.pending, (state) => {
        state.jobFunctionTagsStatus = 'loading';
      })
      .addCase(updateJobFunctionTagThunk.fulfilled, (state, { payload }) => {
        const index = state.jobFunctionTags.findIndex(
          (s) => s.id === payload.id,
        );
        if (index !== -1) {
          state.jobFunctionTags[index] = payload;
        } else {
          state.jobFunctionTags.push(payload);
        }
        state.jobFunctionTagsStatus = 'succeeded';
      })
      .addCase(updateJobFunctionTagThunk.rejected, (state) => {
        state.jobFunctionTagsStatus = 'failed';
      })
      .addCase(deleteJobFunctionTagThunk.pending, (state) => {
        state.jobFunctionTagsStatus = 'loading';
      })
      .addCase(deleteJobFunctionTagThunk.fulfilled, (state, { payload }) => {
        state.jobFunctionTagsStatus = 'succeeded';
        const index = state.jobFunctionTags.findIndex((s) => s.id === payload);
        if (index > -1) {
          state.jobFunctionTags.splice(index, 1);
        }
      })
      .addCase(deleteJobFunctionTagThunk.rejected, (state) => {
        state.jobFunctionTagsStatus = 'failed';
      })
      .addCase(getMCellEmployeesLoggedInThunk.pending, (state) => {
        state.mCellEmployeesLoggedInStatus = 'loading';
      })
      .addCase(
        getMCellEmployeesLoggedInThunk.fulfilled,
        (state, { payload }) => {
          state.mCellEmployeesLoggedInStatus = 'succeeded';
          state.mCellEmployeesLoggedIn = payload;
        },
      )
      .addCase(getMCellEmployeesLoggedInThunk.rejected, (state) => {
        state.mCellEmployeesLoggedInStatus = 'failed';
      })
      .addCase(
        getMCellEmployeesInManufacturingLocationThunk.pending,
        (state) => {
          state.mCellEmployeesInMCellStatus = 'loading';
        },
      )
      .addCase(
        getMCellEmployeesInManufacturingLocationThunk.fulfilled,
        (state, { payload }) => {
          state.mCellEmployeesInMCellStatus = 'succeeded';
          state.mCellEmployeesInMCell = payload;
        },
      )
      .addCase(
        getMCellEmployeesInManufacturingLocationThunk.rejected,
        (state) => {
          state.mCellEmployeesInMCellStatus = 'failed';
        },
      )
      .addCase(getMCellLoginLastUpdatedDateThunk.pending, (state) => {
        state.loginLastUpdatedDateStatus = 'loading';
      })
      .addCase(
        getMCellLoginLastUpdatedDateThunk.fulfilled,
        (state, { payload }) => {
          state.loginLastUpdatedDateStatus = 'succeeded';
          state.loginLastUpdatedDate = payload;
        },
      )
      .addCase(getMCellLoginLastUpdatedDateThunk.rejected, (state) => {
        state.loginLastUpdatedDateStatus = 'failed';
      })
      .addCase(getMCellEmployeeSelectionThunk.pending, (state) => {
        state.mCellEmployeeSelectionStatus = 'loading';
      })
      .addCase(
        getMCellEmployeeSelectionThunk.fulfilled,
        (state, { payload }) => {
          state.mCellEmployeeSelectionStatus = 'succeeded';
          state.mCellEmployeeSelection = payload;
        },
      )
      .addCase(getMCellEmployeeSelectionThunk.rejected, (state) => {
        state.mCellEmployeeSelectionStatus = 'failed';
      })
      .addCase(downloadEmployeeReportCsvThunk.pending, (state) => {
        state.reportsStatus = 'loading';
      })
      .addCase(downloadEmployeeReportCsvThunk.fulfilled, (state) => {
        state.reportsStatus = 'succeeded';
      })
      .addCase(downloadEmployeeReportCsvThunk.rejected, (state) => {
        state.reportsStatus = 'failed';
      })
      .addCase(checkEmployeeRefreshStatusThunk.pending, (state) => {
        state.employeeRefreshRunningStatus = 'loading';
      })
      .addCase(
        checkEmployeeRefreshStatusThunk.fulfilled,
        (state, { payload }) => {
          state.employeeRefreshRunningStatus = 'succeeded';
          state.employeeRefreshRunning = payload.isLeased;
        },
      )
      .addCase(checkEmployeeRefreshStatusThunk.rejected, (state) => {
        state.employeeRefreshRunningStatus = 'failed';
      });
  },
});

/**
 * Selector to determine whether Job Functions are loading
 * @param state
 */
export const selectLoadingJobFunctions = (state: RootState) =>
  state.mCellEmployee.jobFunctionsStatus === 'loading';

/**
 * Selector to select Job Functions
 * @param state
 */
export const selectJobFunctions = (state: RootState) =>
  state.mCellEmployee.jobFunctions;

/**
 * Selector to determine whether Job Function Tags are loading
 * @param state
 */
export const selectLoadingJobFunctionTags = (state: RootState) =>
  state.mCellEmployee.jobFunctionTagsStatus === 'loading';

/**
 * Selector to select Job Function Tags
 * @param state
 */
export const selectJobFunctionTags = (state: RootState) =>
  state.mCellEmployee.jobFunctionTags;

/**
 * Selector to select logged in MCell Employees
 * @param state
 */
export const selectMCellEmployeesLoggedIn = (state: RootState) =>
  state.mCellEmployee.mCellEmployeesLoggedIn;

/**
 * Selector to determine whether logged in MCell Employees are loading
 * @param state
 */
export const selectLoadingMCellEmployeesLoggedIn = (state: RootState) =>
  state.mCellEmployee.mCellEmployeesLoggedInStatus === 'loading';

/**
 * Selector to select logged in MCell Employees
 * @param state
 */
export const selectMCellEmployeesInManufacturingLocation = (state: RootState) =>
  state.mCellEmployee.mCellEmployeesInMCell;

/**
 * Selector to determine whether logged in ManufacturingLocation Employees are loading
 * @param state
 */
export const selectLoadingMCellEmployeesInManufacturingLocation = (
  state: RootState,
) => state.mCellEmployee.mCellEmployeesInMCellStatus === 'loading';

/**
 * Selector to select MCell Employee Selection
 * @param state
 */
export const selectMCellEmployeeSelection = (state: RootState) =>
  state.mCellEmployee.mCellEmployeeSelection;

/**
 * Selector to determine whether MCell Employees Selection are loading
 * @param state
 */
export const selectLoadingMCellEmployeeSelection = (state: RootState) =>
  state.mCellEmployee.mCellEmployeeSelectionStatus === 'loading';

/**
 * Selector to select MCell Login last updated date
 * @param state
 */
export const selectLoginLastUpdatedDate = (state: RootState) =>
  state.mCellEmployee.loginLastUpdatedDate;

/**
 * Selector to determine whether MCell Login last updated date is loading
 * @param state
 */
export const selectLoadingLoginLastUpdatedDate = (state: RootState) =>
  state.mCellEmployee.loginLastUpdatedDateStatus === 'loading';

/**
 * Selector to retrieve the status of the employee refresh process
 * @param state
 */
export const selectEmployeeRefreshRunningStatus = (state: RootState) =>
  state.mCellEmployee.employeeRefreshRunning;

/**
 * Selector to determine whether the employee refresh process is loading
 */
export const selectLoadingEmployeeRefreshRunningStatus = (state: RootState) =>
  state.mCellEmployee.employeeRefreshRunningStatus === 'loading';

export default mCellEmployeeSlice.reducer;
